import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import { ButtonLink } from '../components/ButtonLink'
import { Layout } from '../components/Layout'
import { RawContentSection } from '../components/RawContentSection'
import { SEO } from '../components/SEO'
import { toReviewsData } from '../transform'

import head from '../images/head-sm.png'

const Reviews = ({
  data: {
    prismic: { global_fields, review_page },
  },
}) => {
  const {
    title,
    description,
    customerFeedback,
    submissionDescription,
    email,
    pressTitle,
    links,
    friendsTitle,
    friends,
  } = toReviewsData({ ...review_page, ...global_fields })

  return (
    <Layout>
      <SEO title={RichText.asText(title)} />
      <section className="bg-white px-4 py-8 md:py-12 lg:py-16">
        <div className="container max-w-xl">
          <h1 className="text-5xl md:text-6xl">{RichText.asText(title)}</h1>
          <div className="mt-4">
            <RichText render={description} />
          </div>
          <div className="mt-12">
            {customerFeedback.map(({ name, text }) => (
              <div key={name} className="border-4 border-gray-200 rounded p-4 md:p-8 mb-8">
                <div className="italic">
                  <RawContentSection content={<RichText render={text} />} />
                </div>
                <p className="text-right font-bold text-sm">- {name}</p>
              </div>
            ))}
          </div>
          <div className="text-center mt-16">
            <div className="max-w-lg mx-auto">
              <div className="text:lg">
                <RichText render={submissionDescription} />
              </div>
              <p className="mt-2">
                <a className="hover:underline text-2xl font-heading" href={`mailto:${email}`}>
                  {email}
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-200 px-4 py-8 md:py-12 lg:py-16">
        <div className="container max-w-3xl">
          <h2 className="text-3xl md:text-4xl">{RichText.asText(pressTitle)}</h2>
          <div className="mt-6 flex flex-col sm:flex-row sm:flex-wrap sm:-mx-4">
            {links.map(({ description, linkText, title, url }) => (
              <div key={RichText.asText(title)} className="sm:px-4 sm:w-1/2">
                <div className="border-4 border-gray-300 rounded p-6 mb-8 sm:flex sm:flex-col sm:justify-between">
                  <div>
                    <h3 className="mb-3 font-body capitalize text-xl">{RichText.asText(title)}</h3>
                    <RichText render={description} />
                  </div>
                  <ButtonLink
                    className="mt-6 w-full text-center"
                    small
                    href={url}
                    target="_blank"
                    rel="no-referrer no-opener"
                  >
                    {RichText.asText(linkText)}
                  </ButtonLink>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="container max-w-3xl mt-8">
          <h2 className="text-3xl md:text-4xl">{RichText.asText(friendsTitle)}</h2>
          <div className="mt-6 flex flex-col sm:flex-row sm:flex-wrap sm:-mx-4">
            {friends.map(({ description, linkText, title, url }) => (
              <div key={RichText.asText(title)} className="sm:px-4 sm:w-1/2">
                <div className="border-4 border-gray-300 rounded p-6 mb-8 sm:flex sm:flex-col sm:justify-between">
                  <div>
                    <h3 className="mb-3 font-body capitalize text-xl">{RichText.asText(title)}</h3>
                    <RichText render={description} />
                  </div>
                  <ButtonLink
                    className="mt-6 w-full text-center"
                    small
                    href={url}
                    target="_blank"
                    rel="no-referrer no-opener"
                  >
                    {RichText.asText(linkText)}
                  </ButtonLink>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      global_fields(lang: "en-nz", uid: "global") {
        email_address
      }
      review_page(lang: "en-nz", uid: "reviews") {
        ...reviewsPage
      }
    }
  }
`

export default Reviews
